import React from 'react';
import {Breadcrumb, Input, Button,Pagination } from 'antd';
import {LeftOutlined, UserOutlined, SearchOutlined,} from '@ant-design/icons';
import barner1 from "../image/1.png";
import { useParams,Link } from 'react-router-dom';

const Course = () => {
    const items = [
        {
            title: (
                <>
                    <LeftOutlined/>
                    <Link to="/">首页</Link>
                </>
            ),
        },
      ]

    return (
        <>
            <div style={{
                width: '100%',
                minWidth: "1200px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto'
            }}>
                <div style={{
                    marginTop: "20px",
                    // minHeight: 380,
                    width: '1200px',
                }}>
                    <Breadcrumb items={items}/>
                    <Input
                        style={{width: 400, marginTop: 20}}
                        placeholder={"请输入关键字"}
                        suffix={
                            <Button
                                type="text"
                                icon={<SearchOutlined/>}
                                onClick={() => {
                                    // 处理点击放大镜图标的事件
                                }}
                            />}
                    />
                </div>

            </div>
            <div style={{
                width: '100%',
                minWidth: "1200px",
                marginTop: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto'
            }}>
                <div style={{
                    width: '1200px',
                    minHeight: 580,
                    // backgroundColor: "pink",
                    display: "grid",
                    gridTemplateColumns:'repeat(4,1fr)',
                    gridGap:'20px'

                }}>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                    <div>
                        <img src={barner1} style={{width: '260px', height: '150px'}}/>
                        <p>课程标题</p>
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%',
                minWidth: "1200px",
                marginTop: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto'
            }}>
                <div style={{
                    width: '1200px',
                    // backgroundColor: "pink",

                }}>
            <Pagination defaultCurrent={1} total={50} />
                </div>
            </div>

        </>

    );
};

export default Course;