import axios from 'axios';
import CryptoJS from 'crypto-js'
import moment from 'moment';
// const baseURL = process.env.NODE_ENV === 'production' ? 'http://121.40.67.120/api/' : process.env.NODE_ENV === 'development' ? 'http://localhost:3003/hairi_api/' : 'https://test.zmrobo.com/hairi_api/';
const baseURL = process.env.REACT_APP_ENV === 'production' ? 'https://www.mlxx313.com/api/' : process.env.REACT_APP_ENV === 'development' ? 'http://localhost:3003/hairi_api/' : 'https://test.zmrobo.com/hairi_api/';
const timeout = 10000;
const instance = axios.create({
    baseURL: baseURL,
    timeout: timeout,
});
//动态获取请求的url
const getPathFromConfig = (config) => {
    const { url } = config;
    return url;
};

instance.interceptors.request.use(
    async (config) => {
        const diffInSeconds = Number(localStorage.getItem('serverTimefontend'))
        const number = moment().unix();
        const path = getPathFromConfig(config);
        const method = config.method.toUpperCase();
        const date = number + diffInSeconds;
        const data = `PATH ${path} METHOD ${method} HAIRI DATE ${date}`;
        const hmac = CryptoJS.HmacSHA256(data, CryptoJS.MD5(path).toString());
        const sign = CryptoJS.enc.Base64.stringify(hmac);
        config.headers['number'] = date;
        config.headers['Sign'] = sign;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 统一的请求方法
export default function request(method, url, data, type = 'json') {
    method = method.toLowerCase();
    switch (method) {
        case 'get':
            return instance.get(url, { params: data });
        case 'post':
            if (type === 'multipart') {
                const formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
                return instance.post(url, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            } else {
                return instance.post(url, data, {
                    headers: { 'Content-Type': 'application/json' },

                });
            }
        case 'put':
            return instance.put(url, data)
        case 'patch':
            return instance.patch(url, data);
        case 'delete':
            return instance.delete(url, { params: data });
        default:
            console.error(`Invalid method: ${method}`);
            return null;
    }
}
