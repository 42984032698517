import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from "../layouts/MainLayout";
import Course from "../pages/Course";
import Home from "../pages/Home";
import Download from "../pages/Download";
import Message from "../pages/Message";
import Announcement from "../pages/Announcement";
import AnnouncementDetail from "../pages/AnnouncementDetail";

const AppRouter = () => {
 
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/course" element={<Course />} />
                <Route path="/download" element={<Download />} />
                <Route path="/message" element={<Message />} />
                <Route path="/announcement" element={<Announcement />} />
                <Route path="/announcementDetail/:id" element={<AnnouncementDetail />} />
            </Route>
        </Routes>
    );
};

export default AppRouter;
