// import request from '../utils/request';

// //获取服务器时间
// export function getServeTime() {
//     return request('get', '/system/time');
// }
// // 留言列表
// export function getMessageList(data) {
//     return request('get', '/api/message', (data));
// }
// // 留言提交
// export function submitMessage(data) {
//     return request('post', '/api/message', (data));
// }
// // 留言短信
// export function sendMessage(data) {
//     return request('post', '/api/verification', (data));
// }
// // 轮播图
// export function getBannerList(data) {
//     return request('get', '/api/banner/resource', (data));
// }
// //资讯动态
// export function getNewsList(data) {
//     return request('get', '/api/news', (data));
// }
// export function getNewsContentList(data) {
//     return request('get', '/api/news/content', (data));
// }

import request from '../utils/request';
//获取服务器时间
export function getServeTime() {
    return request('get', '/system/time');
}
// 留言列表
export function getMessageList(data) {
    return request('get', '/message', (data));
}
// 留言提交
export function submitMessage(data) {
    return request('post', '/message', (data));
}
// 留言短信
export function sendMessage(data) {
    return request('post', '/verification', (data));
}
// 轮播图
export function getBannerList(data) {
    return request('get', '/banner/resource', (data));
}
//资讯动态
export function getNewsList(data) {
    return request('get', '/news', (data));
}
export function getNewsContentList(data) {
    return request('get', '/news/content', (data));
}
