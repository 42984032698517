import React, { useState } from "react";
const MessageList = ({ list }) => {
    const [hovered, setHovered] = useState(false);
    let { question, questioner, answer, respondent } = list;

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const hidePhoneNumber = (phoneNumber)=> {
        if (phoneNumber.length === 11) {
            return phoneNumber.slice(0, 3) + '*****' + phoneNumber.slice(-3);
        } else {
            return "Invalid phone number format";
        }
    }
    
    const hiddenNumber = hidePhoneNumber(questioner);
    

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            // border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '15px',
            marginTop: '20px',
            backgroundColor: '#F2FBFF',
            cursor: 'pointer',
            transform: hovered ? 'scale(1.05)' : 'scale(1)',
            transition: 'transform 0.2s',
        }}

            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: "20px" }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ color: 'gray' }}>提问: {question}</span>
                    <span>提问者: {hiddenNumber}</span>
                </div>
            </div>
            <div style={{ borderBottom: '1px solid #ccc', margin: '20px 0', marginRight: "20px" }}></div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', marginRight: "20px" }}>
                <span style={{ color: 'gray' }}>{answer ? `回答: ${answer} ` : '待回答'}</span>
                {answer && <span style={{ display: 'inline-block', textAlign: 'right' }}>{respondent}</span>}
            </div>
        </div>
    );
};
MessageList.defaultProps = {
    question: null,
    questioner: null,
    answer: null,
    author: null
};

export default MessageList;
