import React, { useState, useEffect } from 'react';
import { Breadcrumb, Button, Input, Pagination, Modal, Card, Empty, message } from "antd";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import MessageList from "../component/messageList";
import QueueAnim from 'rc-queue-anim';
import { getMessageList, submitMessage, sendMessage } from '@/services'
import { debounce } from 'lodash';
import HairiButton from "../component/hairiButton";
import { useParams,Link } from 'react-router-dom';

const { TextArea } = Input;

const Message = () => {
    const [open, setOpen] = useState(false);
    const [messageData, setMessageData] = useState([]); //留言列表数据
    const [pageTotal, setPageTotal] = useState(0); //总条数

    const [loading, setLoading] = useState(false);//发送短信加载
    const [sent, setSent] = useState(false);//是否已经发送
    const [phoneNumber, setPhoneNumber] = useState('');//手机号
    const [verificationCode, setVerificationCode] = useState('');//验证码
    const [textValue, setTextValue] = useState('');//留言的值
    const [currentPage, setCurrentPage] = useState(1);



    const items = [
        {
            title: (
                <>
                    <LeftOutlined />
                    <Link to="/">首页</Link>
                </>
            ),
        },
    ]
    const getMessageSource = async (page, size) => {
        const res = await getMessageList({ page, size })
        if (res.code === 0) {
            setMessageData(res.data.list)
            setPageTotal(res.data.total)
        } else {
            message.error(res.msg)
        }
    }
    const showModal = () => {
        setOpen(true);
        setTextValue('')
        setPhoneNumber('')
        setVerificationCode('')
    };
    const handleOk = debounce(async () => {
        const res = await submitMessage({ mobile: phoneNumber, message: textValue, code: verificationCode })
        if (res.code === 0) {
            message.success('留言成功')
            getMessageSource(currentPage, 5)
        } else {
            message.error(res.msg)
        }
        setOpen(false);
        setSent(false)
    }, 1000);
    const handleOkTip = async () => {
        message.warning('请先填写留言,手机号以及验证噢')
    };

    const handleInputChange = (e) => {
        setPhoneNumber(e.target.value);
    };
    const handleVerificationCodeChange = (e) => {
        setVerificationCode(e.target.value);
    };
    const handleTextChange = (e) => {
        setTextValue(e.target.value);
    };

    const handleCancel = () => {
        setOpen(false);
        setSent(false)
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const enterLoading = async () => {
        setLoading(true);
        const res = await sendMessage({ mobile: phoneNumber })
        if (res.code === 0) {
            setTimeout(() => {
                setLoading(false);
                setSent(true);
            }, 3000); // 假设2秒后加载完成

        } else {
            setLoading(false);
            setSent(true);
            message.error(res.msg)
        }

    };

    const canClickButton = phoneNumber.length === 11 && /^\d{11}$/.test(phoneNumber);
    const canClickOkButton = phoneNumber && verificationCode && textValue;
    const totalPage = Math.ceil(pageTotal / 5) * 10; // 计算总页数

    useEffect(() => {
        getMessageSource(currentPage, 5)
    }, [currentPage])
    return (
        <>
            <div style={{ width: '100%', minWidth: "1200px", display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto' }}>
                <div style={{ marginTop: "20px", width: '1200px' }} >
                    <Breadcrumb items={items} />
                    {/* <Button type="primary" style={{ marginTop: 20 }} onClick={showModal}>发布留言</Button> */}
                    <div style={{ marginTop: 20 }}>
                        <HairiButton  onClick={showModal} buttonText="发布留言"></HairiButton>
                    </div>


                </div>
            </div>
            <div style={{ width: '100%', minWidth: "1200px", display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto' }}>
                <div style={{ width: '1200px', height: "655px" }}>
                    <QueueAnim style={{ marginTop: 20 }}  >
                        {messageData.length > 0 ? (messageData.slice(0, 5).map((item, index) => (
                            <div key={index}>
                                <MessageList list={item} />
                            </div>
                        ))) :
                            (<Card >
                                <Empty description='暂未留言噢' />
                            </Card>)
                        }
                    </QueueAnim>
                </div>
            </div>
            <div style={{ width: '100%', minWidth: "1200px", marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto' }}>
                <div style={{ width: '1200px' }}>
                    {messageData.length > 0 && (<Pagination defaultCurrent={currentPage} currentPage={currentPage} total={totalPage} onChange={handlePageChange} />)}
                </div>
            </div>
            <Modal
                style={{ left: -8, top: 260, minWidth: "1300px", }}
                open={open}
                onOk={canClickOkButton ? handleOk : handleOkTip}
                okText="发布留言"
                maskClosable={false}
                onCancel={handleCancel}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ whiteSpace: 'nowrap', }}>提问者手机号</label>
                            <Input
                                style={{ marginLeft: "20px" }}
                                type="number"
                                placeholder="请输入手机号"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                title="请输入有效的手机号格式，例如：123-456-7890"
                                value={phoneNumber}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ whiteSpace: 'nowrap', }}>验证码</label>
                            <Input style={{ width: "150px", marginLeft: "20px", marginRight: "20px" }} type="text" placeholder="请输入验证码" value={verificationCode} onChange={handleVerificationCodeChange} />

                            <Button style={{ width: '100px' }} loading={loading} onClick={enterLoading} disabled={!canClickButton}>
                                {loading ? '加载中' : (sent ? '已发送' : '获取验证码')}
                            </Button>
                        </div>
                        <OkBtn />
                    </div>
                )}
            >
                <p>请输入留言</p>
                <TextArea rows={4} value={textValue} onChange={handleTextChange} />
            </Modal>
        </>
    );
};

export default Message;



