import React from 'react';
import { List, Typography } from 'antd';

const DynamicInfo = ({ messages, onTextClick }) => {
    return (
        <div style={{ width: '1120px', margin: '0 auto', }}>
            <List
                dataSource={messages}
                renderItem={item => (
                    <List.Item>
                        <Typography.Text onClick={() => onTextClick(item.id)} style={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "700px", }}>{item.title}</Typography.Text>
                        <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.5)', marginLeft: "50px" }}>{item.create_time.substring(0, 10)}</Typography.Text>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default DynamicInfo;
