import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss'
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
// let rootDom = document.getElementById("root");
// rootDom.style.height = `${window.innerHeight}px`;
// window.addEventListener("resize", () => {
//   rootDom.style.width = `${window.innerWidth}px`;
//   console.log("Window innerHeight:", window.innerHeight);
//   console.log("Window innerWidth:", window.innerWidth);
// });
function fun() {
   let doc = document.getElementById("root");
   let docW = doc.clientWidth / 10 + 'px';
   doc.style.fontSize = docW;
   // console.log(docW, '宽');
}
fun();
window.addEventListener("resize", fun);
root.render(
   <BrowserRouter
      basename={process.env.REACT_APP_ENV === "test" ? "mlxx313" : "/"}
   >
      <App />
   </BrowserRouter>

);
