import React, { useState } from "react";

const CustomDiv = ({ title, description, time,onClick  }) => {
    const [hovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    return (
        <div
            style={{
                width: '1200px',
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#F2FBFF',
                marginBottom: '10px',
                cursor: 'pointer',
                transform: hovered ? 'scale(1.05)' : 'scale(1)',
                transition: 'transform 0.2s',
                borderRadius: '10px',
            }}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <span style={{ color: 'gray', marginLeft: '20px',userSelect: "none" }}>{title}</span>
            <span style={{ flex: 1, textAlign: 'left', marginLeft: '50px',userSelect: "none", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',  }}>{description}</span>
            <span style={{ marginRight: '20px' ,userSelect: "none"}}>{time}</span>
        </div>
    );
};
export default CustomDiv;