import React, { useState, useEffect } from 'react';
import { Breadcrumb, Layout, Menu, theme, Carousel, FloatButton } from 'antd';
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import styles from './MainLayout.module.scss'
import logo from '../image/logo.svg'
import QRcode from '../image/QRcode.svg'
import DynamicInfo from "../component/dynamicInfo";
import '../iconfont/iconfont.css'

const { Header, Content, Footer } = Layout;

const MainLayout = () => {
    const navigate = useNavigate();
    const storedIndex = localStorage.getItem('currentKey');
    const [activeIndex, setActiveIndex] = useState(storedIndex !== null ? parseInt(storedIndex) : 0);
    // const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index);
        switch (index) {
            case 0:
                navigate('/')
                localStorage.setItem('currentKey', index);
                break
            case 1:
                window.open('http://www.mlxx313.com/match', '_blank');
                navigate('/')
                setActiveIndex(0)
                break
            case 2:
                navigate('/download')
                localStorage.setItem('currentKey', index);
                break
            default:
                break;
        }
    };


    return (
        <Layout>
            <Header
                style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: "white",
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                }}
            >
                <div className={styles.headerBox}>
                    <div className={styles.logoBox}>
                        <img src={logo} alt="魔磷官网Logo" style={{ marginRight: '10px' }} />
                        <p style={{ userSelect: "none", whiteSpace: "nowrap" }}>重庆信息科技活动平台</p>
                    </div>
                    <nav className={styles.bottomNav}>
                        <a className={activeIndex === 0 ? styles.selected : ''} onClick={() => handleClick(0)} style={{ userSelect: "none" }}>首页</a>
                        <a className={activeIndex === 1 ? styles.selected : ''} onClick={() => handleClick(1)} style={{ userSelect: "none" }}>竞赛平台</a>
                        <a className={activeIndex === 2 ? styles.selected : ''} onClick={() => handleClick(2)} style={{ userSelect: "none" }}>软件下载</a>
                    </nav>
                </div>
            </Header>
            <div style={{ backgroundColor: "#FFFFFF", paddingBottom: "300px" }}>
                <Outlet />
            </div>
            <div
                style={{
                    width: '100%',
                    minWidth: "1200px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F2FBFF',
                    overflow: "hidden"
                }}
            >
                <div style={{
                    height: '483px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    backgroundColor: '#F2FBFF',
                    flexDirection: 'column',
                }}>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "flex-start", width: '1200px', paddingTop: "80px", }}>
                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <div style={{ display: 'flex', alignItems: "center" }}>
                                <img src={logo} alt="魔磷官网Logo" style={{ marginRight: '10px' }} />
                                <p style={{ userSelect: "none", color: "#606F7E" }}>重庆信息科技活动平台</p>
                            </div>
                            <div>
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    <div style={{ width: '32px', height: "32px", backgroundColor: "#FFFFFF", display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
                                        <i className="iconfont icon-mail" style={{ fontSize: "20px" }} />
                                    </div>
                                    <p style={{ color: "#606F7E" }}>13368135521@163.com</p>
                                    <p style={{ marginLeft: "70px", color: "#606F7E" }}>技术单位支持：重庆魔磷科技有限公司</p>
                                </div>
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    <div style={{ width: '32px', height: "32px", backgroundColor: "#FFFFFF", display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10 }}>
                                        <i className="iconfont icon-phone" style={{ fontSize: "20px" }} />
                                    </div>
                                    <p style={{ color: "#606F7E" }}>02363711777</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                            <div style={{ width: 100, height: 100, }} >
                                <img src={QRcode} style={{ width: '100%', height: "100%" }} />
                            </div>
                        </div>
                    </div>
                    <hr style={{ width: '100vw', borderTop: '1px solid #CCCCCC' }} />
                    <div style={{ width: '1200px', marginBottom: "100px" }}>
                        <p style={{ color: '#CCCCCC' }}>© 2002-现在 版权所有 重庆信息科技活动平台 保留所有权利<a href="https://beian.miit.gov.cn/" target='_blank'>渝ICP备2024032270号-1</a></p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default MainLayout;