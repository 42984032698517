// import React from 'react'

// const hairiButton = ({ onClick, buttonText }) => {
//   const buttonStyle = {
//     background: 'linear-gradient(to right, #65caff, #3c83ff)',
//     border: 'none',
//     borderRadius: '20px',
//     color: 'white',
//     padding: '10px 20px',
//     cursor: 'pointer',
//     width: '100px'
//   }

//   return (
//     <div>
//       <button style={buttonStyle} onClick={onClick}>{buttonText}</button>
//     </div>
//   )
// }

// export default hairiButton

// import React, { useState } from 'react'

// const HairiButton = ({ onClick, buttonText }) => {
//   const [isFocused, setIsFocused] = useState(false);

//   const buttonStyle = {
//     background: isFocused ? 'linear-gradient(to right, #3c83ff, #1e3f99)' : 'linear-gradient(to right, #65caff, #3c83ff)',
//     border: 'none',
//     borderRadius: '20px',
//     color: 'white',
//     padding: '10px 20px',
//     cursor: 'pointer',
//     width: '100px'
//   }

//   return (
//     <div>
//       <button 
//         style={buttonStyle} 
//         onClick={onClick}
//         onFocus={() => setIsFocused(true)}
//         onBlur={() => setIsFocused(false)}
//       >
//         {buttonText}
//       </button>
//     </div>
//   )
// }

// export default HairiButton


import React, { useState } from 'react'

const HairiButton = ({ onClick, buttonText }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const buttonStyle = {
    background: isClicked ? 'linear-gradient(to right, #1e3f99, #1e3f99)' : isHovered ? 'linear-gradient(to right, #3c83ff, #1e3f99)' : 'linear-gradient(to right, #65caff, #3c83ff)',
    border: 'none',
    borderRadius: '20px',
    color: 'white',
    padding: '10px 20px',
    cursor: 'pointer',
    width: '100px',
    transition: 'background 0.3s'
  }

  return (
    <div>
      <button 
        style={buttonStyle} 
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={() => setIsClicked(true)}
        onMouseUp={() => setIsClicked(false)}
      >
        {buttonText}
      </button>
    </div>
  )
}

export default HairiButton
