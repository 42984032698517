import React, { useEffect, useState } from 'react'
import { Breadcrumb, Input, Button } from 'antd';
import { LeftOutlined, SearchOutlined, } from '@ant-design/icons';
import { useParams, Link } from 'react-router-dom';
import { getNewsContentList } from '@/services'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill';

const AnnouncementDetail = () => {
    const modules = {
        toolbar: [

        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];
    const { id } = useParams();
    const paramId = parseInt(id);
    const [dataSource, setDataSource] = useState([]);
    const items = [
        {
            title: (
                <>
                    <LeftOutlined />
                    <Link to="/">首页</Link>
                </>
            ),
        },
        {
            title: <span>{dataSource.title}</span>
        },
    ]

    const getNewsListContentSource = async () => {
        const res = await getNewsContentList({ id: paramId });
        if (res.code === 0) {
            setDataSource(res.data.content[0])
        }
    }

    // const htmlContent = createFromBlocks( dataSource.content).toHTML();


    // const safeHtml = { __html: htmlContent };
    useEffect(() => {
        getNewsListContentSource()
    }, [])
    return (
        <>
            <div style={{
                width: '100%',
                minWidth: "1200px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto'
            }}>
                <div style={{
                    marginTop: "20px",
                    width: '1200px',
                }}>
                    <Breadcrumb items={items} />
                    <div style={{
                        width: '1200px',

                        minHeight: 580,
                        backgroundColor: '#F2FBFF',
                        marginTop: '20px',
                        padding: '20px',
                        // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        margin: '20px', /* 添加此行以实现水平居中 */

                    }}>
                        {/* <div style={{ display: "flex", }}> */}
                        {/* <div style={{maxWidth:"1000px", width:"1000px",wordWrap: 'break-word'}} dangerouslySetInnerHTML={{ __html: dataSource.content }} /></div> */}
                        {/* <div style={{ width: "100%", overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: dataSource.content }} /> */}
                        {/* <div dangerouslySetInnerHTML={safeHtml} /> */}
                        <div style={{ width: "100%", wordWrap: 'break-word' }}>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                // formats={formats}
                                value={dataSource.content}
                                readOnly={true}
                                // style={{ border: 'none' }}
                                // className="no-toolbar" // 自定义类名用于隐
                                className="no-toolbar no-border" // 自定义类名用于隐藏工具栏和边框线
                            />
                            <style>
                                {/* {`.no-toolbar .ql-toolbar { display: none; }`}  */}
                                {`
                                 .no-toolbar .ql-toolbar { display: none; } /* 隐藏工具栏 */
                                .no-border .ql-container { border: none; } /* 去掉边框线 */
                                    `}
                            </style>
                        </div>
                        {/* </div> */}
                    </div>
                </div>

            </div>
        </>
    )
}

export default AnnouncementDetail
