import React, { useEffect } from 'react'
import { ConfigProvider } from 'antd';
import AppRouter from './router/AppRouter';
import moment from 'moment';
import { getServeTime } from '@/services'
import AutoScorllTop from './component/AutoTop'
// import AutoScorllTop from '@/src/component/AutoTop'
const App = () => {
    // 获取服务器时间处理差值
    const getTimeSolve = async () => {
        const newTime = moment().unix();
        const res = await getServeTime();
        const diff2 = res - newTime;
        localStorage.setItem('serverTimefontend', diff2)
        return diff2;
    }
    useEffect(() => {
        getTimeSolve()
    }, [])

    return (
        <ConfigProvider theme={{ token: { colorPrimary: '#226FFF' } }}>
            <AutoScorllTop>
                <AppRouter />
            </AutoScorllTop>
        </ConfigProvider>
    )
}
export default App
