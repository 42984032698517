import React, { useEffect, useState } from 'react';
import { Breadcrumb, Input, Button, Pagination, Card, Empty } from 'antd';
import { LeftOutlined, SearchOutlined, } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import CustomDiv from "../component/textList";
import { useNavigate,Link } from "react-router-dom";
import { getNewsList } from '@/services'

const Announcement = () => {
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState('全部');
    const [newsList, setNewsList] = useState([]);//新闻列表
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPage, setTotalPage] = useState(0);
    const [searchVal, setSearchVal] = useState('');

    const items = [
        {
            title: (
                <>
                    <LeftOutlined />
                    <Link to="/">首页</Link>
                </>
            ),
        },
    ]

    // const getNewsListSource = async ({ page, size, key, classification }) => {
    //     const params = { page, size, ...(key && { key }), ...(classification && { classification }) };
    //     const res = await getNewsList(params);
    //     if (res.code === 0) {
    //         setNewsList(res.data.list)
    //         setTotalPage(res.data.total)
    //     }
    // }
    // const getNewsListSource = async (page, size) => {
    //     const res = await getNewsList({ page, size})
    //     if (res.code === 0) {
    //         setNewsList(res.data.list)
    //         setTotalPage(res.data.total)
    //     }
    // }
    const getNewsListSource = async (page, size, key, classification) => {
        const params = { page, size };
        if (key) {
            params.key = key;
        }
        if (classification) {
            params.classification = classification;
        }
        const res = await getNewsList(params);
        if (res.code === 0) {
            setNewsList(res.data.list);
            setTotalPage(res.data.total);
            const newPage = parseInt(res.data.currentPage)
            setCurrentPage(newPage);
        }
    }


    const handleTypeClick = (type) => {
        setSelectedType(type);
        if (type === "全部") {
            getNewsListSource(currentPage, pageSize);
        } else {
            const value = type === "新闻" ? 1 : 2;
            getNewsListSource(currentPage, pageSize, false, value);
        }
    };


    const handleClick = (id) => {
        navigate(`/announcementDetail/${id}`);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const onChangeInput = (e) => {
        setSearchVal(e.target.value);
    };
    const handleSearch = () => {
        getNewsListSource(currentPage, pageSize, searchVal)
    };

    useEffect(() => {
        getNewsListSource(currentPage, pageSize)
    }, [currentPage, pageSize])
    return (
        <>
            <div style={{
                width: '100%',
                minWidth: "1200px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto'
            }}>
                <div style={{
                    marginTop: "20px",
                    width: '1200px',
                }}>
                    <Breadcrumb items={items} />
                    <div style={{ userSelect: "none", height: "40px", display: 'flex', alignItems: 'center', marginLeft: 2, marginTop: 20 }}>
                        <span style={{ marginRight: '10px' }}>类型 : </span>
                        <span
                            style={{ cursor: 'pointer', marginLeft: '30px', borderBottom: selectedType === '全部' ? '2px solid #226FFF' : 'none',color: selectedType === '全部' ? '#226FFF' : 'black'  }}
                            onClick={() => handleTypeClick('全部')}
                        >
                            全部
                        </span>
                        <span
                            style={{ cursor: 'pointer', marginLeft: '30px', borderBottom: selectedType === '赛事' ? '2px solid #226FFF' : 'none', color: selectedType === '赛事' ? '#226FFF' : 'black' }}
                            onClick={() => handleTypeClick('赛事')}
                        >
                            赛事
                        </span>
                        <span
                            style={{ cursor: 'pointer', marginLeft: '30px', borderBottom: selectedType === '新闻' ? '2px solid #226FFF' : 'none', color: selectedType === '新闻' ? '#226FFF' : 'black' }}
                            onClick={() => handleTypeClick('新闻')}
                        >
                            新闻
                        </span>
                    </div>

                    <Input
                        style={{ width: 400, marginTop: 20 }}
                        placeholder={"请输入关键字"}
                        onChange={onChangeInput}
                        onPressEnter={handleSearch}
                        value={searchVal}
                        suffix={
                            <Button
                                type="text"
                                icon={<SearchOutlined />}
                                onClick={handleSearch}
                            />}
                    />
                    <QueueAnim style={{ marginTop: 20 }}  >
                        {newsList.length > 0 ? (newsList.slice(0, 10).map((item, index) => (
                            <div key={index}>
                                <CustomDiv title={item.name} description={item.title} time={item.create_time.substring(0, 10)} onClick={() => handleClick(item.id)} />
                            </div>
                        ))) :
                            (<Card >
                                <Empty description='暂未留言噢' />
                            </Card>)
                        }
                    </QueueAnim>
                    <div style={{ width: '100%', minWidth: "1200px", marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'auto' }}>
                        <div style={{ width: '1200px' }}>
                            {newsList.length > 0 && (<Pagination defaultCurrent={currentPage} currentPage={currentPage} total={totalPage} onChange={handlePageChange} />)}
                        </div>
                    </div>
                </div>

            </div>

        </>

    );
};

export default Announcement;